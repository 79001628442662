import { Checkbox, Collapse, Flex, MantineStyleProp, Modal, Notification, rem, ScrollArea, Select, Slider, Text } from "@mantine/core";
import React from "react";
import { text_md_regular } from "../shared/text.css";
import { PrimaryButton, SecondaryButton } from "../shared/buttons";
import { SettingKeys, SettingType } from "../../lib/localstorage/settings";
import { getDevicePerformanceScore, isMobileDevice } from "../../lib/misc/user-device";
import { IconExclamationMark } from "@tabler/icons-react";
import { gray } from "../../styles/colors";
import { readLocalStorageValue, useLocalStorage } from "@mantine/hooks";

function BooleanSetting(props: {
  label: string;
  checked: boolean;
  onChange: (newChecked: boolean) => void
  style?: MantineStyleProp
}) {
  return (
    <Flex justify="start" gap={8} pt={4} style={props.style}>
      <Checkbox
        color="purple"
        checked={props.checked}
        onChange={(e) => props.onChange(e.currentTarget.checked)}
      />
      <Text className={text_md_regular}>{props.label}</Text>
    </Flex>
  );
}

export function SearchSettings(props: {
  opened: boolean;
  open: () => void;
  close: () => void;
}) {
  const [reminder, setReminder] = useLocalStorage({
    key: SettingKeys.RemindMeToSave,
    defaultValue: false
  });
  const [openTab, setOpenTab] = useLocalStorage({
    key: SettingKeys.OpenNewTab,
    defaultValue: true
  })
  const [maskTitle, setMaskTitle] = useLocalStorage({
    key: SettingKeys.MaskTitle,
    defaultValue: true
  })
  const [viewTimeline, setViewTimeline] = useLocalStorage({
    key: SettingKeys.ViewTimeline,
    defaultValue: true
  })
  const [viewMap, setViewMap] = useLocalStorage({
    key: SettingKeys.ViewMap,
    defaultValue: true
  })
  const [viewGraph, setViewGraph] = useLocalStorage<boolean>({
    key: SettingKeys.ViewGraph
  });
  const [graphThreshold, setGraphThreshold] = useLocalStorage<number>({
    key: SettingKeys.GraphThreshold
  });
  const grahThresholdMarks = [
    { value: 10, label: '10' },
    { value: 35, label: '35' },
    { value: 60, label: '60' },
  ];
  const [reverseImageSearchType, setReverseImageSearchType] = useLocalStorage({
    key: SettingKeys.ReverseImageSearchType,
    defaultValue: 'Google Lens'
  })
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [recommendedNodesCount, setRecommendedNodesCount] = React.useState<number>(20);
  const [showWarning, setShowWarning] = React.useState<boolean>(true);

  const getRecommendedNodesCount = () => {
    const score = getDevicePerformanceScore();
    const recommendedNodes = score * 4 / 10;

    return Math.min(60, Math.ceil(recommendedNodes));
  }

  const nodesInRange = (count: number) => count >= 10 && count <= 60;

  React.useEffect(() => {
    setIsMobile(isMobileDevice());
    const viewGraph = readLocalStorageValue<SettingType<SettingKeys.ViewGraph>>(
      { key: SettingKeys.ViewGraph }
    ) ?? !isMobileDevice();
    setViewGraph(viewGraph);
    const recommendedCount = getRecommendedNodesCount();
    setRecommendedNodesCount(recommendedCount);
    const threshold = readLocalStorageValue<SettingType<SettingKeys.GraphThreshold>>(
      { key: SettingKeys.GraphThreshold }
    ) ?? recommendedCount;
    if (nodesInRange(threshold)) {
      setGraphThreshold(threshold);
    } else {
      if (nodesInRange(recommendedCount)) {
        setGraphThreshold(recommendedCount);
      } else {
        const viewGraphFalse = readLocalStorageValue<SettingType<SettingKeys.ViewGraph>>(
          { key: SettingKeys.ViewGraph }
        ) ?? !isMobileDevice();
        setViewGraph(viewGraphFalse);
        setGraphThreshold(10);
      }
    }
  }, [setViewGraph, setGraphThreshold]);

  const resetSettings = () => {
    setReminder(false);
    setOpenTab(true);
    setMaskTitle(true);
    setShowWarning(true);
    setReverseImageSearchType('Google Lens');
    setViewTimeline(true);
    setViewMap(true);
    setViewGraph(!isMobileDevice());
    if (nodesInRange(recommendedNodesCount)) {
      setGraphThreshold(recommendedNodesCount);
    } else {
      setGraphThreshold(10);
      setViewGraph(false);
    }
  }


  return (
    <Modal
      title={'Search settings'}
      padding="lg"
      closeOnClickOutside={true}
      onClose={props.close}
      centered
      opened={props.opened}
      zIndex={901}
    >
      <ScrollArea offsetScrollbars type="always">
        <div style={{ height: viewGraph ? rem(325) : rem(300) }}>
          <BooleanSetting
            label="Remind me to save my results"
            checked={reminder}
            onChange={setReminder}
          />
          <BooleanSetting
            label="Open new tab for searches"
            checked={openTab}
            onChange={setOpenTab}
            style={{
              marginTop: rem(4)
            }}
          />
          <BooleanSetting
            label="Mask query from result page title"
            checked={maskTitle}
            onChange={setMaskTitle}
            style={{
              marginTop: rem(4),
              marginBottom: rem(18)
            }}
          />
          <Flex
            direction={'column'}
            style={{
              paddingTop: rem(10),
              borderTop: '1px solid var(--mantine-color-gray-8)'
            }}
          >
            <Text style={{ marginBottom: rem(20) }}>Result settings</Text>
            <BooleanSetting
              label="View timeline"
              checked={viewTimeline}
              onChange={setViewTimeline}
              style={{
                marginTop: rem(4)
              }}
            />
            <BooleanSetting
              label="View map"
              checked={viewMap}
              onChange={setViewMap}
              style={{
                marginTop: rem(4)
              }}
            />
            <BooleanSetting
              label="View graph"
              checked={viewGraph}
              onChange={setViewGraph}
              style={{
                marginTop: rem(4),
                marginBottom: rem(10)
              }}
            />
            <Collapse in={viewGraph}>
              <Flex
                justify="start"
                gap={8}
                pt={rem(4)}
                style={{
                  marginTop: rem(4),
                  marginBottom: rem(15)
                }}
              >
                <Text className={text_md_regular}>
                  {graphThreshold} modules seen in graph view
                </Text>
                <Slider
                  w={'30%'}
                  value={graphThreshold}
                  defaultValue={graphThreshold}
                  onChange={setGraphThreshold}
                  onChangeEnd={setGraphThreshold}
                  min={10}
                  max={60}
                  marks={grahThresholdMarks}
                  radius={'xs'}
                  styles={{
                    thumb: {
                      border: '3px solid white'
                    }
                  }}
                />
              </Flex>
              {viewGraph && showWarning && (isMobile || graphThreshold > recommendedNodesCount) &&
                <Flex
                  justify="start"
                  gap={8}
                  pt={rem(4)}
                  style={{
                    marginTop: rem(4),
                    marginBottom: rem(15)
                  }}
                >
                  <Notification
                    icon={<IconExclamationMark style={{ width: rem(20), height: rem(20) }} />}
                    color="yellow"
                    title="Warning"
                    closeButtonProps={{ 'aria-label': 'Hide notification' }}
                    onClose={() => setShowWarning(false)}
                  >
                    {isMobile ? 'Graph View is currently not recommended for mobile devices'
                      : recommendedNodesCount < 10 ? 'Graph View is currently not recommended for your device'
                        : graphThreshold > recommendedNodesCount ? 'Your system may not be able to handle this amount of entities. Please proceed with caution.'
                          : 'Graph View is currently not recommended for your device'
                    }
                  </Notification>
                </Flex>}
            </Collapse>
            <Flex
              align="center"
              style={{
                marginTop: rem(10),
                marginBottom: rem(15),
              }}
            >
              <Text
                c={gray[5]}
                style={{
                  marginRight: rem(10)
                }}
              >
                Reverse image search:
              </Text>
              <Select
                placeholder="Pick reverse image search type"
                data={['Google Lens', 'Google Image Search', 'Yandex Image Search']}
                value={reverseImageSearchType}
                onChange={(value) => setReverseImageSearchType(value!)}
                allowDeselect={false}
                w={rem(200)}
                styles={{
                  label: {
                    color: gray[5]
                  },
                  dropdown: {
                    zIndex: 9001,
                  },
                }}
              />
            </Flex>
          </Flex>
        </div></ScrollArea>
      <Flex
        justify="space-between"
        style={{
          paddingTop: rem(15),
          marginTop: rem(15),
          borderTop: '1px solid var(--mantine-color-gray-8)'
        }}
      >
        <SecondaryButton
          onClick={() => resetSettings()}
        >
          Reset settings
        </SecondaryButton>
        <PrimaryButton
          onClick={props.close}
        >
          Save
        </PrimaryButton>
      </Flex>

    </Modal>
  )
}
