'use client';

import {
  ActionIcon,
  Flex,
  TextInput,
  Text,
  rem,
  Checkbox,
  Tooltip,
  rgba,
  Loader,
} from '@mantine/core';
import {
  IconAdjustmentsHorizontal,
  IconArrowRight,
  IconSearch,
} from '@tabler/icons-react';
import { gray, purple, red } from '../../styles/colors';
import {
  desktopButton,
  formstyles,
  input,
  inputContainer,
  inputError,
  inputRoot,
  mobileButton,
} from './form.css';
import React, { FormEvent } from 'react';
import Link from 'next/link';
import { text_md_regular } from '../shared/text.css';
import { readLocalStorageValue, useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { PrimaryButton, SecondaryButton } from '../shared/buttons';
import { clientEnvs } from '../../lib/envs/client';
import { getAllCountries, getCountryISO2, getPrefixFromISO2, setNewQueryUuid, validateInputQuery } from '../shared/input-query-handlers';
import { CountrySelector } from 'react-international-phone';
import 'react-international-phone/style.css';
import classes from '../shared/animated.module.css';
import { SearchTypesButtons } from '../shared/search-types-dropdown';
import { SearchSettings } from './search-settings';
import { SettingKeys, SettingType } from '../../lib/localstorage/settings';

export const SearchForm = () => {
  const [error, setError] = React.useState<string | undefined>();
  const [val, setVal] = React.useState('');
  const [debounced] = useDebouncedValue(val, 500);
  const [queryValidState, setQueryValidState] = React.useState<
    'Email' | 'Phone' | 'Username' | 'Invalid' | null | 'loading'
  >(null);
  const [checked, setChecked] = React.useState(false);
  const [phoneSearchCountry, setPhoneSearchCountry] = React.useState('us');
  const [searchType, setSearchType] = React.useState<'Email' | 'Phone' | 'Username' | 'Automatic'>('Automatic');
  const [searchSettingsOpened, searchSettings] = useDisclosure(false);
  const firstRenderRef = React.useRef(true);

  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    } else {
      setQueryValidState('loading');
      validateInputQuery(debounced, searchType).then((queryState) => {
        setQueryValidState(queryState);
        if (queryState === "Phone") {
          const iso2 = getCountryISO2(debounced);
          setPhoneSearchCountry(iso2);
        }
        if (queryState === 'Invalid' && debounced !== '') {
          setError("Invalid query!");
        } else {
          setError(undefined);
        }
      });
    }
  }, [debounced, searchType]);

  const setPhonePrefix = (iso2: string) => {
    setPhoneSearchCountry(iso2);
    const newPrefix = getPrefixFromISO2(iso2);
    setVal(newPrefix || "");
  }

  const handleClick = (e: FormEvent) => {
    e.preventDefault();
    if (error !== undefined) return;
    const isOpenNewTab = readLocalStorageValue<SettingType<SettingKeys.OpenNewTab>>({key: SettingKeys.OpenNewTab}) 
      ?? true;
    window.open(
      `/results?query=${setNewQueryUuid(debounced.replace(/^\s+|\s+$/g, ''))}`,
      isOpenNewTab ? '_blank' : '_self'
    );
    setVal('');
  };

  let disabled =
    queryValidState === 'loading' ||
    queryValidState === 'Invalid' ||
    queryValidState === null;
  if (!checked) {
    disabled = true;
  }

  return (
    <form onSubmit={handleClick} className={formstyles}>
      <Flex
        align="center"
        className={inputContainer}
        direction="column"
        gap={rem(10)}
      >
        <SearchTypesButtons state={queryValidState} searchType={searchType} setSearchType={setSearchType} />
        <TextInput
          w="100%"
          leftSection={
            <Flex>
              {(queryValidState === "Phone" || searchType === "Phone") &&
                <CountrySelector
                  countries={getAllCountries()}
                  selectedCountry={phoneSearchCountry}
                  onSelect={({ iso2 }) => setPhonePrefix(iso2)}
                  style={{
                    marginLeft: "1.25rem",
                    marginRight: "-1.75rem"
                  }}
                  buttonStyle={{
                    background: "none",
                    border: "none"
                  }}
                  dropdownStyleProps={{
                    style: {
                      background: "#2e2e2e"
                    },
                    listItemStyle: {
                      background: "#2e2e2e"
                    },
                    listItemCountryNameStyle: {
                      color: "#ffffff"
                    }
                  }}
                />
              }
            </Flex>
          }
          value={val}
          min={3}
          required
          onChange={(e) => setVal(e.target.value)}
          placeholder={searchType === "Phone" ? "Enter phone number" :
            searchType === "Email" ? "Enter email address" :
              searchType === "Username" ? "Enter username" : "Enter phone number, email or username"}
          classNames={{
            wrapper: inputRoot,
            input: error !== undefined ? `${input} ${inputError}` : input,
          }}
          styles={{
            error: {
              color: red[5],
            },
            input: {
              paddingBottom: 2,
              paddingLeft: (queryValidState === "Phone" || searchType === "Phone") ? rem(66) : rem(20)
            },
          }}
          error={error}
          rightSection={
            <Tooltip
              style={{
                WebkitBackdropFilter: 'blur(10px)',
                backdropFilter: 'blur(10px)',
                backgroundColor: rgba(purple[3], 0.12),
                border: `1px solid ${purple[6]}`,
                borderRadius: rem(12),
                color: 'white',
              }}
              p={12}
              w={rem(200)}
              multiline
              label={'Search settings'}
            >
              <ActionIcon onClick={searchSettings.open} variant='subtle' color='gray' className={classes.animated_border}>
                <IconAdjustmentsHorizontal />
              </ActionIcon>
            </Tooltip>
          }
        />
        <Underline checked={checked} setChecked={setChecked} />
        <SearchSettings
          opened={searchSettingsOpened}
          open={searchSettings.open}
          close={searchSettings.close}
        />
        <Flex mt={16} justify="center">
          {disabled ? (
            queryValidState === 'loading' ? <Loader /> : <SecondaryButton
              styles={{
                root: {
                  backgroundColor: 'var(--mantine-color-gray-11)',
                },
                label: {
                  color: 'var(--mantine-color-gray-6)',
                },
              }}
              className={mobileButton}
              w={200}
              rightSection={<IconArrowRight color={gray[6]} />}
            >
              Search
            </SecondaryButton>
          ) : (
            queryValidState === 'loading' ? <Loader /> :
              <PrimaryButton
                className={mobileButton}
                type="submit"
                w={200}
                rightSection={<IconArrowRight />}
              >
                Search
              </PrimaryButton>
          )}
        </Flex>
      </Flex>
      <ActionIcon
        className={desktopButton}
        type="submit"
        p={rem(18)}
        style={{
          height: rem(60),
          width: rem(60),
          borderRadius: rem(10),
        }}
        styles={{
          icon: {
            height: rem(25),
            width: rem(25),
          },
        }}
        variant="filled"
        color="purple.7"
        disabled={disabled}
      >
        <IconSearch
          style={{
            height: rem(25),
            width: rem(25),
          }}
        />
      </ActionIcon>
    </form>
  );
};

function Underline(props: {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Flex
      style={{ cursor: 'pointer' }}
      tabIndex={0}
      onClick={() => props.setChecked((p) => !p)}
      justify="center"
      w="100%"
      gap={8}
      pt={rem(4)}
    >
      <Checkbox
        color="purple"
        checked={props.checked}
        onChange={(e) => props.setChecked(e.currentTarget.checked)}
      />
      <Text className={text_md_regular}>
        By selecting search you agree to our
        <Link
          href={`${clientEnvs.webflowSite}terms-conditions`}
          className={text_md_regular}
          style={{
            color: 'var(--mantine-color-purple-6)',
            marginLeft: rem(5),
            textDecoration: 'underline',
          }}
        >
          Terms of use
        </Link>{' '}
      </Text>
    </Flex>
  );
}
