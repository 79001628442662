const { validate, version } = require('uuid');
import libphoneNumber from 'google-libphonenumber';
import { CountryData, defaultCountries } from 'react-international-phone';

export const isUUIDv4 = (value: string): boolean => {
  return validate(value) && version(value) === 4;
}

export const getQueryUuidString = (queryUuid: string | string[] | undefined): string => {
  if (!queryUuid) return "";
  return Array.isArray(queryUuid) ? queryUuid.join('') : queryUuid;
}

export const getQueryFromUuid = (queryUuid: string): string => {
  if (!isUUIDv4(queryUuid)) return queryUuid;
  if (!queryUuid || !localStorage.getItem(queryUuid)) return '';
  const query = localStorage.getItem(queryUuid);
  localStorage.removeItem(queryUuid);
  return query || '';
}

export const setNewQueryUuid = (query: string): string => {
  const uuid = crypto.randomUUID();
  localStorage.setItem(uuid, query);
  return uuid;
}

export const validateInputQuery = async (
  query: string,
  searchType: 'Email' | 'Phone' | 'Username' | 'Automatic'
): Promise<'Email' | 'Phone' | 'Username' | 'Invalid'> => {
  query = query.replace(/^\s+|\s+$/g, '');
  await new Promise((r) => setTimeout(r, 300)); // Delay for loading state
  if (searchType === 'Email')
    return parseEmailQuery(query);
  else if (searchType === 'Phone')
    return parsePhoneNumberQuery(query);
  else if (searchType === 'Username')
    return parseUsernameQuery(query);
  else {
    if (query.includes('@')) {
      return parseEmailQuery(query);
    }
    if (query.match(/[a-zA-Z._-]/))
      return parseUsernameQuery(query);
    else {
      let result = parsePhoneNumberQuery(query);
      if (result === 'Invalid') {
        return parseUsernameQuery(query);
      } else {
        return result;
      }
    }
  }
};

const parseEmailQuery = (email: string): 'Email' | 'Invalid' => {
  const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  const valid = EMAIL_REGEX.test(email);
  if (valid) {
    return 'Email';
  } else {
    return 'Invalid';
  }
};

const parsePhoneNumberQuery = (phone: string): 'Phone' | 'Invalid' => {
  phone =
    '+' +
    phone
      .replace(/\s/g, '')
      .replace(/\u202d/g, '')
      .replace(/[^\x00-\x7F]/g, '')
      .replace('+', '');

  if (!phone.startsWith('+')) {
    phone = '+' + phone;
  }

  const phoneUtil = libphoneNumber.PhoneNumberUtil.getInstance();
  try {
    const parsedNumber = phoneUtil.parse(phone, 'ZZ');
    const regionCode = phoneUtil.getRegionCodeForNumber(parsedNumber);
    if (
      parsedNumber &&
      regionCode &&
      (phoneUtil.isValidNumber(parsedNumber) ||
        phoneUtil.isPossibleNumber(parsedNumber))
    ) {
      return 'Phone';
    } else {
      return 'Invalid';
    }
  } catch {
    return 'Invalid';
  }
};

export const getCountryISO2 = (phoneNumber: string): string => {
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = '+' + phoneNumber;
  }
  const phoneUtil = libphoneNumber.PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parse(phoneNumber, 'ZZ');
    const regionCode = phoneUtil.getRegionCodeForNumber(number);
    const countryCode = number.getCountryCode();
    if (countryCode === 44) {
      return "gb";
    }

    return regionCode?.toLowerCase() || "us";
  } catch (error) {
    console.error('Error parsing phone number:', error);
    return "us";
  }
}

export const getPrefixFromISO2 = (iso2: string): string | null => {
  const phoneUtil = libphoneNumber.PhoneNumberUtil.getInstance();
  try {
    const countryCode = phoneUtil.getCountryCodeForRegion(iso2.toUpperCase());
    return `+${countryCode}`;
  } catch (error) {
    console.error('Error getting country code for region:', error);
    return null;
  }
}

const getMissingCountries = (): CountryData[] => {
  return [
    ['Anguilla', 'ai', '1'],
    ['American Samoa', 'as', '1'],
    ['Bermuda', 'bm', '1'],
    ['Northern Mariana Islands', 'mp', '1'],
    ['Montserrat', 'ms', '1'],
    ['Sint Maarten', 'sx', '1'],
    ['Turks and Caicos Islands', 'tc', '1'],
    ['British Virgin Islands', 'vg', '1'],
    ['United States Virgin Islands', 'vi', '1'],
    ['Guernsey', 'gg', '44'],
    ['Isle of Man', 'im', '44'],
    ['Jersey', 'je', '44'],
    ['Svalbard and Jan Mayen', 'sj', '47'],
    ['Cocos (Keeling) Islands', 'cc', '61'],
    ['Christmas Island', 'cx', '61'],
    ['Western Sahara', 'eh', '212'],
    ['Ascension Island', 'ac', '247'],
    ['Mayotte', 'yt', '262'],
    ['Saint Helena', 'sh', '290'],
    ['Tristan da Cunha', 'ta', '290'],
    ['Faroe Islands', 'fo', '298'],
    ['Gibraltar', 'gi', '350'],
    ['Åland Islands', 'ax', '358'],
    ['Falkland Islands', 'fk', '500'],
    ['Saint Pierre and Miquelon', 'pm', '508'],
    ['Saint Barthélemy', 'bl', '590'],
    ['Saint Martin', 'mf', '590'],
    ['Norfolk Island', 'nf', '672'],
    ['Wallis and Futuna', 'wf', '681'],
    ['Cook Islands', 'ck', '682'],
    ['Niue', 'nu', '683'],
    ['Tokelau', 'tk', '690']
  ];
};

export const getAllCountries = () => {
  return [...defaultCountries, ...getMissingCountries()].sort((a, b) => {
    const nameA = a[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const nameB = b[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}

const parseUsernameQuery = (username: string): 'Username' | 'Invalid' => {
  const USERNAME_REGEX = /^[a-zA-Z0-9._-]+$/;
  const valid = USERNAME_REGEX.test(username) && username.length > 3 && username.length < 30;
  if (valid) {
    return 'Username';
  } else {
    return 'Invalid';
  }
};
