import { ActionIcon, Flex, Group, Loader, Menu, rem, rgba, Tooltip } from "@mantine/core";
import { IconCheck, IconMail, IconPhone, IconSearch, IconUser } from "@tabler/icons-react";
import classes from './animated.module.css';
import { purple } from "../../styles/colors";
import { SecondaryButtonWithRef } from "./buttons";

export const SearchTypesDropdown = (props: {
    state: 'Email' | 'Phone' | 'Username' | 'Invalid' | null | 'loading';
    searchType: 'Email' | 'Phone' | 'Username' | 'Automatic';
    setSearchType: React.Dispatch<React.SetStateAction<'Email' | 'Phone' | 'Username' | 'Automatic'>>;
}) => {
    const dropdownMenuIcon: Record<string, React.JSX.Element> = {
        "Email": <IconMail size={24} />,
        "Phone": <IconPhone size={24} />,
        "Username": <IconUser size={24} />,
        "Automatic": <IconSearch size={24} />,
        "loading": <Loader size={24} />,
    }

    return <Menu position='bottom-start'>
        <Menu.Target>
            <Group>
                <Tooltip
                    style={{
                        WebkitBackdropFilter: 'blur(10px)',
                        backdropFilter: 'blur(10px)',
                        backgroundColor: rgba(purple[3], 0.12),
                        border: `1px solid ${purple[6]}`,
                        borderRadius: rem(12),
                        color: 'white',
                    }}
                    p={12}
                    w={rem(200)}
                    multiline
                    label={'Search selectors'}
                >
                    {props.searchType === "Automatic" && props.state !== "Phone" ?
                        <ActionIcon variant='subtle' color='gray' className={classes.animated_border}>
                            {props.state && dropdownMenuIcon[props.state] || dropdownMenuIcon[props.searchType]}
                        </ActionIcon> :
                        <ActionIcon variant='subtle' color='gray'>
                            {props.state && dropdownMenuIcon[props.state] || dropdownMenuIcon[props.searchType]}
                        </ActionIcon>
                    }
                </Tooltip>
            </Group>
        </Menu.Target>
        <Menu.Dropdown>
            <Menu.Item
                leftSection={<IconSearch style={{ width: rem(14), height: rem(14) }} />}
                rightSection={props.searchType === "Automatic" && <IconCheck style={{ width: rem(14), height: rem(14) }} />}
                onClick={() => props.setSearchType("Automatic")}
            >
                Automatic
            </Menu.Item>
            <Menu.Item
                leftSection={<IconPhone style={{ width: rem(14), height: rem(14) }} />}
                rightSection={props.searchType === "Phone" && <IconCheck style={{ width: rem(14), height: rem(14) }} />}
                onClick={() => props.setSearchType("Phone")}
            >
                Phone
            </Menu.Item>
            <Menu.Item
                leftSection={<IconMail style={{ width: rem(14), height: rem(14) }} />}
                rightSection={props.searchType === "Email" && <IconCheck style={{ width: rem(14), height: rem(14) }} />}
                onClick={() => props.setSearchType("Email")}
            >
                Email
            </Menu.Item>
            <Menu.Item
                leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}
                rightSection={props.searchType === "Username" && <IconCheck style={{ width: rem(14), height: rem(14) }} />}
                onClick={() => props.setSearchType("Username")}
            >
                Username
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
};

export const SearchTypesButtons = (props: {
    state: 'Email' | 'Phone' | 'Username' | 'Invalid' | null | 'loading';
    searchType: 'Email' | 'Phone' | 'Username' | 'Automatic';
    setSearchType: React.Dispatch<React.SetStateAction<'Email' | 'Phone' | 'Username' | 'Automatic'>>;
}) => {
    return <Flex>
        <Tooltip
            multiline
            w={390}
            label={"Please enter the phone number in international format (e.g., '123-456-7890' becomes '+1 123 456 7890' for US, '01 23 45 67 89' becomes '+33 1 23 45 67 89' for France)."}
            withArrow
            position="top"
        >
            <SecondaryButtonWithRef
                leftSection={<IconPhone style={{ width: rem(14), height: rem(14) }} />}
                onClick={() => { props.searchType === "Phone" ? props.setSearchType("Automatic") : props.setSearchType("Phone") }}
                style={{
                    border: props.searchType === "Phone" ? '2px solid var(--mantine-color-gray-6)'
                        : '1px solid var(--mantine-color-gray-8)',
                    borderRadius: 8,
                    height: 48,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                }}
                color={"gray.10"}
                className={ props.searchType === "Phone" ? classes.vignette_shine_background : 
                    props.state === 'Phone' && props.searchType === 'Automatic' ?classes.vignette_shine_background_light : "" }
            >
                Phone
            </SecondaryButtonWithRef>
        </Tooltip>
        <Tooltip
            multiline
            w={250}
            label={"Please enter a valid email address (e.g., name@example.com)."}
            withArrow
            position="top"
        >
            <SecondaryButtonWithRef
                leftSection={<IconMail style={{ width: rem(14), height: rem(14) }} />}
                onClick={() => { props.searchType === "Email" ? props.setSearchType("Automatic") : props.setSearchType("Email") }}
                style={{
                    border: props.searchType === "Email" ? '2px solid var(--mantine-color-gray-6)'
                    : '1px solid var(--mantine-color-gray-8)',
                    borderRadius: 0,
                    height: 48
                }}
                color={"gray.10"}
                className={ props.searchType === "Email" ? classes.vignette_shine_background : 
                    props.state === 'Email' && props.searchType === 'Automatic' ? classes.vignette_shine_background_light : "" }
            >
                Email
            </SecondaryButtonWithRef>
        </Tooltip>
        <Tooltip
            multiline
            w={230}
            label={"Please choose a username with 4-32 characters (e.g., user1234)."}
            withArrow
            position="top"
        >
            <SecondaryButtonWithRef
                leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}
                onClick={() => props.searchType === "Username" ? props.setSearchType("Automatic") : props.setSearchType("Username")}
                style={{
                    border: props.searchType === "Username" ? '2px solid var(--mantine-color-gray-6)'
                    : '1px solid var(--mantine-color-gray-8)',
                    borderRadius: 8,
                    height: 48,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                }}
                color={"gray.10"}
                className={ props.searchType === "Username" ? classes.vignette_shine_background :
                    props.state === 'Username' && props.searchType === 'Automatic' ? classes.vignette_shine_background_light : "" }
            >
                Username
            </SecondaryButtonWithRef>
        </Tooltip>
    </Flex>
};