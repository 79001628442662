export const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const isFirefox = () => /Firefox/i.test(navigator.userAgent);

export const getDevicePerformanceScore = () => {
    // Get device and performance info
    const memory = (navigator as any).deviceMemory || 2; // Estimate memory in GB, fallback to 2GB if not available
    const cores = navigator.hardwareConcurrency || 2; // Estimate number of CPU cores, fallback to 2 if not available
    const userAgent = navigator.userAgent.toLowerCase();
    
    // Estimate performance based on basic device characteristics
    let score = 45; // Start with a base score

    // Memory impact (more memory = better score)
    if (memory >= 8) {
        score += 20;
    } else if (memory >= 4) {
        score += 10;
    } else {
        score += 5;
    }

    // CPU cores impact (more cores = better score)
    if (cores >= 8) {
        score += 20;
    } else if (cores >= 4) {
        score += 10;
    } else {
        score += 5;
    }

    // Simple user-agent check (modern browsers get a small bonus)
    if (userAgent.includes('chrome') || userAgent.includes('firefox') || userAgent.includes('safari')) {
        score += 10;
    } else if (userAgent.includes('edge')) {
        score += 5;
    }

    // Ensure the score doesn't exceed 100
    return Math.min(score, 100);
}