export enum SettingKeys {
  RemindMeToSave = "remind_me_to_save",
  OpenNewTab = "open_new_tab",
  MaskTitle = "mask_title",
  ViewTimeline = "view_timeline",
  ViewMap = "view_map",
  ViewGraph = "view_graph",
  GraphThreshold = "graph_threshold",
  PDFPlusModalShow = "pdf_plus_modal_show",
  ReverseImageModalShow = "reverse_image_modal_show",
  ReverseImageSearchType = "reverse_image_search_type"
}

export type SettingTypes = {
  [SettingKeys.RemindMeToSave]: boolean;
  [SettingKeys.OpenNewTab]: boolean;
  [SettingKeys.MaskTitle]: boolean;
  [SettingKeys.ViewTimeline]: boolean;
  [SettingKeys.ViewMap]: boolean;
  [SettingKeys.ViewGraph]: boolean;
  [SettingKeys.GraphThreshold]: number;
  [SettingKeys.PDFPlusModalShow]: boolean;
  [SettingKeys.ReverseImageModalShow]: boolean;
  [SettingKeys.ReverseImageSearchType]: string;
};

export type SettingType<T extends SettingKeys> = SettingTypes[T];