"use client";

import { Flex, rem, Button, Image, Text } from "@mantine/core";
import {
  text_lg_semibold,
  text_md_semibold,
  text_sm_semibold,
} from "../shared/text.css";
import { useMediaQuery } from "@mantine/hooks";

export const HomePopup = () => {
  const isSmallScreen = useMediaQuery("(max-width: 650px)");

  return (
    <Flex
      justify="center"
      align="center"
      p={rem(10)}
      pl={rem(35)}
      pr={rem(35)}
      style={{
        alignSelf: "center",
        background: "black",
        border: `2px solid #2b2b2c`,
        borderRadius: rem(20),
      }}
      gap="md"
      mb={rem(20)}
    >
      {!isSmallScreen && (
        <Image src="/osint_academy.svg" w={175} h={175} alt="osint-academy" />
      )}
      <Flex direction="column" align={isSmallScreen ? "center" : "start"}>
        <Text
          ta={isSmallScreen ? "center" : "left"}
          c="#929292"
          className={isSmallScreen ? text_sm_semibold : text_lg_semibold}
          maw={rem(400)}
        >
          <Text
            span
            className={isSmallScreen ? text_sm_semibold : text_lg_semibold}
          >
            OSINT Academy
          </Text>{" "}
          is live! Explore online and in-person courses, gain OSINT skills, and
          earn certifications from top experts.
        </Text>
        <Button
          component="a"
          href="https://academy.osint.industries/"
          target="_blank"
          classNames={{
            label: text_md_semibold,
          }}
          styles={{
            label: {
              color: "#a49cdf",
            },
          }}
          color="#181232"
          style={{ border: `1px solid #36286f`, borderRadius: 8, height: 40 }}
          mt={rem(15)}
          w={isSmallScreen ? rem(175) : rem(215)}
        >
          Become Certified
        </Button>
      </Flex>
    </Flex>
  );
};
